<template>
  <NuxtLoadingIndicator color="#673ab7" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
html{
  scroll-behavior: smooth;
}
</style>