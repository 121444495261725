import { default as brandsbj0z5k0wSvMeta } from "D:/project_learning/keshmed-ssr/pages/admin/brands.vue?macro=true";
import { default as categoriessCVjWV4IEcMeta } from "D:/project_learning/keshmed-ssr/pages/admin/categories.vue?macro=true";
import { default as indexzkNeK4lhZsMeta } from "D:/project_learning/keshmed-ssr/pages/admin/index.vue?macro=true";
import { default as orders37696rJjq1Meta } from "D:/project_learning/keshmed-ssr/pages/admin/orders.vue?macro=true";
import { default as indexynXhbKjJwPMeta } from "D:/project_learning/keshmed-ssr/pages/index/index.vue?macro=true";
import { default as _91id_93Wq9Q59dwKSMeta } from "D:/project_learning/keshmed-ssr/pages/index/product/[id].vue?macro=true";
import { default as productsENouHTJdrZMeta } from "D:/project_learning/keshmed-ssr/pages/index/products.vue?macro=true";
import { default as loginrQ5jd9mtdoMeta } from "D:/project_learning/keshmed-ssr/pages/login.vue?macro=true";
import { default as component_45stubhRtkVEbnq6Meta } from "D:/project_learning/keshmed-ssr/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubhRtkVEbnq6 } from "D:/project_learning/keshmed-ssr/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: brandsbj0z5k0wSvMeta?.name ?? "admin-brands___uz",
    path: brandsbj0z5k0wSvMeta?.path ?? "/uz/admin/brands",
    meta: brandsbj0z5k0wSvMeta || {},
    alias: brandsbj0z5k0wSvMeta?.alias || [],
    redirect: brandsbj0z5k0wSvMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/brands.vue").then(m => m.default || m)
  },
  {
    name: brandsbj0z5k0wSvMeta?.name ?? "admin-brands___ru",
    path: brandsbj0z5k0wSvMeta?.path ?? "/admin/brands",
    meta: brandsbj0z5k0wSvMeta || {},
    alias: brandsbj0z5k0wSvMeta?.alias || [],
    redirect: brandsbj0z5k0wSvMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/brands.vue").then(m => m.default || m)
  },
  {
    name: brandsbj0z5k0wSvMeta?.name ?? "admin-brands___en",
    path: brandsbj0z5k0wSvMeta?.path ?? "/en/admin/brands",
    meta: brandsbj0z5k0wSvMeta || {},
    alias: brandsbj0z5k0wSvMeta?.alias || [],
    redirect: brandsbj0z5k0wSvMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/brands.vue").then(m => m.default || m)
  },
  {
    name: categoriessCVjWV4IEcMeta?.name ?? "admin-categories___uz",
    path: categoriessCVjWV4IEcMeta?.path ?? "/uz/admin/categories",
    meta: categoriessCVjWV4IEcMeta || {},
    alias: categoriessCVjWV4IEcMeta?.alias || [],
    redirect: categoriessCVjWV4IEcMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/categories.vue").then(m => m.default || m)
  },
  {
    name: categoriessCVjWV4IEcMeta?.name ?? "admin-categories___ru",
    path: categoriessCVjWV4IEcMeta?.path ?? "/admin/categories",
    meta: categoriessCVjWV4IEcMeta || {},
    alias: categoriessCVjWV4IEcMeta?.alias || [],
    redirect: categoriessCVjWV4IEcMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/categories.vue").then(m => m.default || m)
  },
  {
    name: categoriessCVjWV4IEcMeta?.name ?? "admin-categories___en",
    path: categoriessCVjWV4IEcMeta?.path ?? "/en/admin/categories",
    meta: categoriessCVjWV4IEcMeta || {},
    alias: categoriessCVjWV4IEcMeta?.alias || [],
    redirect: categoriessCVjWV4IEcMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/categories.vue").then(m => m.default || m)
  },
  {
    name: indexzkNeK4lhZsMeta?.name ?? "admin___uz",
    path: indexzkNeK4lhZsMeta?.path ?? "/uz/admin",
    meta: indexzkNeK4lhZsMeta || {},
    alias: indexzkNeK4lhZsMeta?.alias || [],
    redirect: indexzkNeK4lhZsMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexzkNeK4lhZsMeta?.name ?? "admin___ru",
    path: indexzkNeK4lhZsMeta?.path ?? "/admin",
    meta: indexzkNeK4lhZsMeta || {},
    alias: indexzkNeK4lhZsMeta?.alias || [],
    redirect: indexzkNeK4lhZsMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexzkNeK4lhZsMeta?.name ?? "admin___en",
    path: indexzkNeK4lhZsMeta?.path ?? "/en/admin",
    meta: indexzkNeK4lhZsMeta || {},
    alias: indexzkNeK4lhZsMeta?.alias || [],
    redirect: indexzkNeK4lhZsMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: orders37696rJjq1Meta?.name ?? "admin-orders___uz",
    path: orders37696rJjq1Meta?.path ?? "/uz/admin/orders",
    meta: orders37696rJjq1Meta || {},
    alias: orders37696rJjq1Meta?.alias || [],
    redirect: orders37696rJjq1Meta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: orders37696rJjq1Meta?.name ?? "admin-orders___ru",
    path: orders37696rJjq1Meta?.path ?? "/admin/orders",
    meta: orders37696rJjq1Meta || {},
    alias: orders37696rJjq1Meta?.alias || [],
    redirect: orders37696rJjq1Meta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: orders37696rJjq1Meta?.name ?? "admin-orders___en",
    path: orders37696rJjq1Meta?.path ?? "/en/admin/orders",
    meta: orders37696rJjq1Meta || {},
    alias: orders37696rJjq1Meta?.alias || [],
    redirect: orders37696rJjq1Meta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: indexynXhbKjJwPMeta?.name ?? "index___uz",
    path: indexynXhbKjJwPMeta?.path ?? "/uz",
    meta: indexynXhbKjJwPMeta || {},
    alias: indexynXhbKjJwPMeta?.alias || [],
    redirect: indexynXhbKjJwPMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexynXhbKjJwPMeta?.name ?? "index___ru",
    path: indexynXhbKjJwPMeta?.path ?? "/",
    meta: indexynXhbKjJwPMeta || {},
    alias: indexynXhbKjJwPMeta?.alias || [],
    redirect: indexynXhbKjJwPMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexynXhbKjJwPMeta?.name ?? "index___en",
    path: indexynXhbKjJwPMeta?.path ?? "/en",
    meta: indexynXhbKjJwPMeta || {},
    alias: indexynXhbKjJwPMeta?.alias || [],
    redirect: indexynXhbKjJwPMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Wq9Q59dwKSMeta?.name ?? "index-product-id___uz",
    path: _91id_93Wq9Q59dwKSMeta?.path ?? "/uz//product/:id()",
    meta: _91id_93Wq9Q59dwKSMeta || {},
    alias: _91id_93Wq9Q59dwKSMeta?.alias || [],
    redirect: _91id_93Wq9Q59dwKSMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Wq9Q59dwKSMeta?.name ?? "index-product-id___ru",
    path: _91id_93Wq9Q59dwKSMeta?.path ?? "//product/:id()",
    meta: _91id_93Wq9Q59dwKSMeta || {},
    alias: _91id_93Wq9Q59dwKSMeta?.alias || [],
    redirect: _91id_93Wq9Q59dwKSMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Wq9Q59dwKSMeta?.name ?? "index-product-id___en",
    path: _91id_93Wq9Q59dwKSMeta?.path ?? "/en//product/:id()",
    meta: _91id_93Wq9Q59dwKSMeta || {},
    alias: _91id_93Wq9Q59dwKSMeta?.alias || [],
    redirect: _91id_93Wq9Q59dwKSMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/product/[id].vue").then(m => m.default || m)
  },
  {
    name: productsENouHTJdrZMeta?.name ?? "index-products___uz",
    path: productsENouHTJdrZMeta?.path ?? "/uz//products",
    meta: productsENouHTJdrZMeta || {},
    alias: productsENouHTJdrZMeta?.alias || [],
    redirect: productsENouHTJdrZMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/products.vue").then(m => m.default || m)
  },
  {
    name: productsENouHTJdrZMeta?.name ?? "index-products___ru",
    path: productsENouHTJdrZMeta?.path ?? "//products",
    meta: productsENouHTJdrZMeta || {},
    alias: productsENouHTJdrZMeta?.alias || [],
    redirect: productsENouHTJdrZMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/products.vue").then(m => m.default || m)
  },
  {
    name: productsENouHTJdrZMeta?.name ?? "index-products___en",
    path: productsENouHTJdrZMeta?.path ?? "/en//products",
    meta: productsENouHTJdrZMeta || {},
    alias: productsENouHTJdrZMeta?.alias || [],
    redirect: productsENouHTJdrZMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/index/products.vue").then(m => m.default || m)
  },
  {
    name: loginrQ5jd9mtdoMeta?.name ?? "login___uz",
    path: loginrQ5jd9mtdoMeta?.path ?? "/uz/login",
    meta: loginrQ5jd9mtdoMeta || {},
    alias: loginrQ5jd9mtdoMeta?.alias || [],
    redirect: loginrQ5jd9mtdoMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginrQ5jd9mtdoMeta?.name ?? "login___ru",
    path: loginrQ5jd9mtdoMeta?.path ?? "/login",
    meta: loginrQ5jd9mtdoMeta || {},
    alias: loginrQ5jd9mtdoMeta?.alias || [],
    redirect: loginrQ5jd9mtdoMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginrQ5jd9mtdoMeta?.name ?? "login___en",
    path: loginrQ5jd9mtdoMeta?.path ?? "/en/login",
    meta: loginrQ5jd9mtdoMeta || {},
    alias: loginrQ5jd9mtdoMeta?.alias || [],
    redirect: loginrQ5jd9mtdoMeta?.redirect,
    component: () => import("D:/project_learning/keshmed-ssr/pages/login.vue").then(m => m.default || m)
  },
  {
    name: component_45stubhRtkVEbnq6Meta?.name ?? undefined,
    path: component_45stubhRtkVEbnq6Meta?.path ?? "/sitemap.xml",
    meta: component_45stubhRtkVEbnq6Meta || {},
    alias: component_45stubhRtkVEbnq6Meta?.alias || [],
    redirect: component_45stubhRtkVEbnq6Meta?.redirect,
    component: component_45stubhRtkVEbnq6
  }
]