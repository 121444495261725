<template>
    <NuxtLayout name="index-layout">
        <v-container class="d-flex align-center justify-center" style="min-height: 50vh;">
            <div class="text-h1 font-weight-bold">
                <span class="text-primary">4</span>
                <span>0</span>
                <span class="text-primary">4</span>
            </div>
        </v-container>
    </NuxtLayout>
</template>